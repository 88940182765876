<template>
  <div>
    <!-- 수리접수/수리현황/수리완료 메뉴 -->
    <CRow class="mb-3">
      <CCol col="2" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
        <CLink style="text-decoration-line: none; color: black;" 
          to="/c_repairReception">
          수리접수
        </CLink>
      </CCol>
      <CCol col="2" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
        <CLink style="text-decoration-line: none; color: black;" 
          to="/c_repairStatus">
          수리현황
        </CLink>
      </CCol>
      <CCol col="2" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">
        <CLink style="text-decoration-line: none; color: black;" 
          to="/c_repairConfirm">
          수리완료/거부
        </CLink>
      </CCol>
    </CRow>
    <!-- 수리의뢰 내용, 로그인 -->
    <CRow>
      <!-- 수리의뢰 내용 -->
      <CCol col="8">
        <CRow>
          <CCol col="12" class="py-2" style="background-color: gray; color: #FFFFFF; display: flex; align-items: center; justify-content: center;">수리의뢰 내용</CCol>
        </CRow>
        <!-- 수리의뢰 반복 영역 -->
        <CRow v-for="item in repairData" v-bind:key="item.index">
          <!-- 좌측 -->
          <CCol col="8">
            <CRow>
              <CCol col="3" class="py-2" style="color: #19C1FF; font-weight: bold;">
                {{item.reservation_type == '방문' ? '방문예약' : ''}}
                {{item.reservation_type == '출장' ? '출장예약' : ''}}
              </CCol>
              <CCol col="4" class="py-2">주문번호 : {{item.order_number}}</CCol>
              <CCol col="5" class="py-2" style="display: flex; justify-content: flex-end;">{{$moment(item.date_created).format('YYYY.MM.DD hh:mm:ss')}}</CCol>
            </CRow>
            <CRow>
              <CCol col="7" class="py-2" style="font-weight: bold;">청구금액 {{item.payment_index.payment_price.toLocaleString()}}원 ({{item.payment_index.payment_state}})</CCol>
              <CCol col="5" class="py-2" style="display: flex; justify-content: flex-end;">컴퓨터 고유번호 : {{item.computer_index_number != null ? item.computer_index_number : '정보없음'}}</CCol>
            </CRow>
            <CRow>
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CRow>
                  <CCol col="9" class="py-2">
                    <CCol col="12" class="py-1" style="font-weight: bold;">수리의뢰 정보</CCol>
                    <CCol col="12" class="py-1">
                      <span style="font-weight: bold;">희망 방문시간<br></span>
                      <span style="font-weight: bold; color: #19C1FF;">{{$moment(Number(item.hope_time_timestamp)).format('YYYY년 MM월 DD일 (ddd) / A hh시 mm분')}}</span>
                    </CCol>
                    <CCol col="12" class="py-1">
                      주소<br>
                      {{item.address}}
                    </CCol>
                    <CCol col="12" class="py-1">
                      연락처<br>
                      {{item.phone}}
                    </CCol>
                    <CCol col="12" class="py-1">
                      간단문진 정보<br>
                      파워 : {{item.self_check_json.power == true ? 'Yes' : 'No'}} / 부팅 : {{item.self_check_json.boot == true ? 'Yes' : 'No'}} / 화면 : {{item.self_check_json.display == true ? 'Yes' : 'No'}} / 넘락 : {{item.self_check_json.numlock == true ? 'Yes' : 'No'}} / 램문제 : {{item.self_check_json.ram == true ? 'Yes' : 'No'}}
                    </CCol>
                    <CCol col="12" class="py-1">
                      사용자 입력 고장정보<br>
                      {{item.self_information}}
                    </CCol>
                  </CCol>
                  <CCol col="3" class="py-2" style="font-weight: bold;">
                    수리대상 사진
                    <img :src="`https://suriyo.api.togethersoft.co.kr/assets/${image.directus_files_id}`" style="width: 100%;" 
                      v-for="image in item.images" v-bind:key="image.id"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <CCol col="12" class="py-2" style="font-size: 18px; font-weight: bold;">주문내역</CCol>
                <CCol col="12">
                  <CRow>
                    <CCol col="9" style="font-weight: bold;">{{item.order_information_json.partner_product_list_index.product_index.name}}</CCol>
                    <CCol col="1">{{item.order_information_json.partner_product_list_index.quantity}}</CCol>
                    <CCol col="2">{{item.order_information_json.partner_product_list_index.price.toLocaleString()}}원</CCol>
                  </CRow>
                  <CRow>
                    <CCol col="9">+ 직접방문 부품교체 공임비</CCol>
                    <CCol col="1"></CCol>
                    <CCol col="2">20,000원</CCol>
                  </CRow>
                  <CRow>
                    <CCol col="9">+ 본체 내부 에어청소</CCol>
                    <CCol col="1"></CCol>
                    <CCol col="2">1,000원</CCol>
                  </CRow>
                </CCol>
              </CCol>
            </CRow>
          </CCol>
          <!-- 우측 -->
          <CCol col="4">
            <CRow>
              <!-- <CCol col="4" class="py-2" @click="reception_reject_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">거부</CCol>
              <CCol col="8" class="py-2" @click="new_reception_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">접수</CCol> -->
              <CCol col="12" class="py-2" @click="new_reception_modal = true" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
                처리 결과 : &nbsp;
                <span style="font-weight: bold; font-size: 18px;">
                  {{item.repair_state}}
                </span>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
                <span style="font-weight: bold;">하드웨어 스캔 정보</span><br><br>

                <span style="font-weight: bold;">CPU-Z</span><br>
                version {{item.hardware_scan_json.CPU_Z_version != null ? item.hardware_scan_json.CPU_Z_version : '정보없음'}}<br><br>

                <span style="font-weight: bold;">Processor info</span><br>
                코어 : {{item.hardware_scan_json.Number_of_cores != null ? item.hardware_scan_json.Number_of_cores : '정보없음'}}<br>
                스레드 : {{item.hardware_scan_json.Number_of_threads != null ? item.hardware_scan_json.Number_of_threads : '정보없음'}}<br>
                CPU : {{item.hardware_scan_json.Specification != null ? item.hardware_scan_json.Specification : '정보없음'}}<br>
                코드네임 : {{item.hardware_scan_json.Codename != null ? item.hardware_scan_json.Codename : '정보없음'}}<br>
                소켓 : {{item.hardware_scan_json.Package != null ? item.hardware_scan_json.Package : '정보없음'}}<br><br>
                
                <span style="font-weight: bold;">Chipset</span><br>
                PCI-E 대역폭 : {{item.hardware_scan_json.PCI_E_Link_Width != null ? item.hardware_scan_json.PCI_E_Link_Width : '정보없음'}}<br>
                RAM 타입 : {{item.hardware_scan_json.Memory_Type != null ? item.hardware_scan_json.Memory_Type : '정보없음'}}<br>
                RAM 총 용량 : {{item.hardware_scan_json.Memory_Size != null ? item.hardware_scan_json.Memory_Size : '정보없음'}}<br>
                RAM 채널 : {{item.hardware_scan_json.Channels != null ? item.hardware_scan_json.Channels : '정보없음'}}<br>
                RAM 클럭 : {{item.hardware_scan_json.Memory_Frequency != null ? item.hardware_scan_json.Memory_Frequency : '정보없음'}}<br><br>
                
                <div v-if="item.hardware_scan_json.Memory != null">
                  <span style="font-weight: bold;">Memory SPD</span><br>
                  RAM #0<br>
                  RAM 제조사 : Samsung (CE00000000000000)<br>
                  RAM 용량 : 8192 MBytes<br>
                  RAM 대역폭 : DDR4-2666 (1333 MHz)
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCol>
      <!-- 우측 로그인 관리 영역 -->
      <CCol col="4">
        <!-- 비로그인일때 -->
        <div v-if="userIndex == null">
            <CRow class="mb-2">
              <CCol col="12">컴퓨터 안심수리 플랫폼 수리요</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="12" class="py-2" style="background-color: #19C1FF; display: flex; align-items: center; justify-content: center; color: #FFFFFF; font-weight: bold;">
                <img :src="require('@/assets/images/home/logo.png')" style="width: 35%;" />
                &nbsp;파트너스 로그인
              </CCol> 
            </CRow>
            <CRow class="mb-2">
              <CCol col="12" class="text-right">
                <span @click="loginPageRoute()">아이디·비밀번호 찾기</span>
                <span> | </span>
                <span>회원가입</span>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="12" class="p-1">
                <CCol col="12" style="border: 1px #707070 solid;">
                  <CCol col="12" class="py-4">수리요 파트너스를 통해<br> 스마트한 수리서비스를 시작해보세요!</CCol>
                  <CCol col="12" class="mb-3 py-2" style="display: flex; align-items: center; justify-content: center; color: #19C1FF; font-weight: bold; border: 1px #707070 solid;">
                    <img :src="require('@/assets/images/home/logo2.png')" style="width: 35%;" />
                    &nbsp;파트너스 시작하기
                  </CCol>
                </CCol>
              </CCol>
            </CRow>
        </div>
        <!-- 로그인일때 -->
        <div v-if="userIndex != null">
          <CRow>
            <CCol col="4" style="display: flex; align-items: center;">
              <div style="width: 45px; height: 45px; border-radius: 23px; overflow: hidden;">
                <img :src="require('@/assets/images/storeManagement_companyRegister/dummy.png')" style="width: 100%;" />
              </div>
            </CCol>
            <CCol col="8" style="padding-left: 0px;">
              <CRow>
                <CCol col="12">
                  <span style="color: #D7E400;">LV.0</span>
                  &nbsp;{{partnerIndexCompanyName}}
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  <img :src="require('@/assets/images/storeManagement_inventory/green.png')" style="width: 15px; height: 15px;" />
                  <span>&nbsp;영업중</span>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol col="12" class="p-1">
              <CCol col="12" style="border: 1px #707070 solid; padding: 0px;">
                <CCol col="12" class="py-2 text-center">채팅상담</CCol>
                <CLink style="text-decoration-line: none; color: #3c4b64;"
                  to="/pages/c_login"
                >
                  <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; border-top: 1px #707070 solid; padding: 0px;">
                    <CCol col="4" style="display: flex; align-items: center;">
                      <div style="width: 45px; height: 45px; border-radius: 23px; overflow: hidden;">
                        <img :src="require('@/assets/images/storeManagement_companyRegister/dummy.png')" style="width: 100%;" />
                      </div>
                    </CCol>
                    <CCol col="8" style="padding-left: 0px;">
                      <CRow>
                        <CCol col="12">
                          <span style="color: #D7E400;">LV.0</span>
                          &nbsp;디엔에스
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="12">
                          <span>예약했습니다.</span>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CCol>
                </CLink>
              </CCol>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>

    <!-- CModal 하단 버튼 코드 수정됨 -->
    <CModal
      title="신규 수리접수"
      :show.sync="new_reception_modal"
      size="xl"
    >
      <div>
        <CRow class="mb-3">
          <CCol col="3" style="font-size: 20px; font-weight: bold;">방문예약</CCol>
          <CCol col="9" style="font-size: 20px; font-weight: bold; color: #19C1FF;">2021년 07월 20일 (화요일) / 11시 00분</CCol>
        </CRow>
        <CCol col="12" class="mb-2 py-2" style="border: 1px #707070 solid;">
          <CCol col="12" class="mb-2" style="font-size: 18px; font-weight: bold;">수리의뢰 정보</CCol>
          <CCol col="12" class="mb-2">
            간단문진 정보<br>
            파워 : No / 부팅 : Null / 화면 : Null / 넘락 : Null / 램문제 : Null
          </CCol>
          <CCol col="12">
            사용자 입력 고장정보<br>
            멀티탭 스위치에 불도 들어오고 코드도 본체에 잘 연결되어 있는데, 전원 버튼을 눌러도 아무 반응이 없어요.
          </CCol>
        </CCol>
        <CCol col="12" class="py-2" style="border: 1px #707070 solid;">
          <CCol col="12" class="mb-2" style="font-size: 18px; font-weight: bold;">주문내역</CCol>
          <CCol col="12" class="mb-3 pb-3" style="border-bottom: 1px #707070 solid;">
            <CRow>
              <CCol col="9" style="font-weight: bold;">마이크로닉스 Classic 2 600W 파워교체</CCol>
              <CCol col="1">1</CCol>
              <CCol col="2">60,000원</CCol>
            </CRow>
            <CRow>
              <CCol col="9">+ 직접방문 부품교체 공임비</CCol>
              <CCol col="1"></CCol>
              <CCol col="2">20,000원</CCol>
            </CRow>
            <CRow>
              <CCol col="9">+ 본체 내부 에어청소</CCol>
              <CCol col="1"></CCol>
              <CCol col="2">1,000원</CCol>
            </CRow>
          </CCol>
          <CCol col="12">
            <CRow>
              <CCol col="9" style="font-weight: bold;"></CCol>
              <CCol col="1">1</CCol>
              <CCol col="2">81,000원</CCol>
            </CRow>
          </CCol>
        </CCol>
        <CCol col="12" class="py-3" style="display: flex; align-items: center; justify-content: center; font-size: 16px; font-weight: bold;">
          자세한 사항은 [수리접수 페이지]를 확인하세요!
        </CCol>
        <CRow>
          <CCol col="6">
            <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center;">거부</CCol>
          </CCol>
          <CCol col="6">
            <CCol col="12" class="py-2" style="border: 1px #707070 solid; display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">접수</CCol>
          </CCol>
        </CRow>
      </div>
    </CModal>
    <CModal
      title="수리거부사유 선택"
      :show.sync="reception_reject_modal"
      size="xl"
    >
      <div>
        <CRow>
          <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; font-size: 22px; font-weight: bold;">수리접수 거부 사유를 선택해주세요</CCol>
        </CRow>
        <CRow class="px-5 py-3">
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">출장불가지역</CCol>
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">취급부품없음</CCol>
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">수리부품 소진</CCol>
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">수리지연</CCol>
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">고객정보 부정확</CCol>
          <CCol col="6" class="py-3" style="display: flex; align-items: center; justify-content: center; border: 1px #707070 solid; font-size: 18px; font-weight: bold;">기타</CCol>
        </CRow>
      </div>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'c_repairReception',
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      new_reception_modal: false,
      reception_reject_modal: false,
      userIndex: null,
      partnerIndex: null,
      partnerIndexCompanyName: '',
      repairData: [],
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
    this.partnerIndexCompanyName = localStorage.getItem('partnerIndexCompanyName');
    this.init();
  },
  methods: {
    // 수리현황 호출
    init() {
      axios.get(`https://suriyo.api.togethersoft.co.kr/items/repair_request?fields=*.*&filter={"_and": [{"partner_index":"${this.partnerIndex}"},{"_or": [{"repair_state":"완료"},{"repair_state":"수령"},{"repair_state":"리뷰작성"},{"repair_state":"리뷰완료"},{"repair_state":"거부"}]}]}`)
      .then((res) => {
        console.log(res);
        this.repairData = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      })
    },
    loginPageRoute() {
      this.$router.push({name: 'c_login', params: {}});
    },
  },
}
</script>
